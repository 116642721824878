import React from "react";
import StarIcon from "../../../img/Star.png";
import LayersIcon from "@mui/icons-material/Layers";

const Reports: React.FC = () => {
  return (
    <div className="py-4 px-2 text-xs">
      <div className="pb-5 px-2 flex justify-between items-center text-xs font-bold gap-2 text-gray-700">
        <div className="flex gap-x-1 items-center">
        <img src={StarIcon} alt="star image" className="Chats_Header_Icon h-[14px] w-[14px]" />
          <p>Kenya Law Reports</p>
        </div>
      </div>
      <ul className="space-y-4 text-[11px] font-medium">
        <li className="flex items-center justify-between">
          <div className="flex items-center">
            <LayersIcon className="Chats_Icon" />
            <span className="cursor-pointer text-gray-500 font-bold transition-colors duration hover:text-gray-700 ">
              Case Search
            </span>
          </div>
        </li>
        <li className="flex items-center justify-between">
          <div className="flex items-center">
            <LayersIcon className="Chats_Icon" />
            <span className="cursor-pointer text-gray-500 transition-colors duration hover:text-gray-700 hover:font-semibold">Kenya Gazette</span>
          </div>
          <div className="Lighter_Shade flex items-center text-blue-500 text-[8px] px-1 py-1 rounded-xl">
            Coming soon <span> <img src={StarIcon} alt="Star Icon" className="h-2 ml-1" /></span>
          </div>
        </li>
        <li className="flex items-center justify-between">
          <div className="flex items-center">
            <LayersIcon className="Chats_Icon" />
            <span className="cursor-pointer text-gray-500 transition-colors duration hover:text-gray-700 hover:font-semibold">Cause list</span>
          </div>
          <div className="Lighter_Shade flex items-center text-blue-500 text-[8px] px-1 py-1 rounded-xl">
            Coming soon <span> <img src={StarIcon} alt="Star Icon" className="h-2 ml-1" /></span>
          </div>
        </li>
        <li className="flex items-center justify-between">
          <div className="flex items-center">
            <LayersIcon className="Chats_Icon" />
            <span className=" cursor-pointer text-gray-500 transition-colors duration hover:text-gray-700 hover:font-semibold">Laws of Kenya</span>
          </div>
          <div className="Lighter_Shade flex items-center text-blue-500 text-[8px] px-1 py-1 rounded-xl">
            Coming soon <span> <img src={StarIcon} alt="Star Icon" className="h-2 ml-1" /></span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Reports;
