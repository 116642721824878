
import { useQuery } from '@tanstack/react-query';
import { fetchUsers } from './fetch-users';
import { User } from './types';

export const useFetchUsers = () => {
  return useQuery<User[], Error>({
    queryKey: ['users'],
    queryFn: fetchUsers,
  });
};
