// AuthContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface AuthContextType {
  isAuthenticated: boolean;
  userId: string | null;
  role: string | null;
  login: (userId: string, role: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);

  useEffect(() => {
    localStorage.getItem('token');
    
  }, []);

  const login = (userId: string, role: string) => {
    setIsAuthenticated(true);
    setUserId(userId);
    setRole(role);
    localStorage.setItem('token', userId);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUserId(null);
    setRole(null);
    
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userId, role, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
