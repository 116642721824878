import LandingHeader from "../(components)/header";
import EmailImg from "../../img/Mail.jpg"

const VerifyEmailAlert = () => {
  return (
    <div>
      <LandingHeader />

      <section className="mx-auto py-12 md:py-24 flex flex-col items-center justify-center ">
        <img
          src={EmailImg}
          alt="email"
          className="h-20 md:h-24 mb-4"
        />
        <h3 className="text-2xl md:text-xl font-bold mb-4 ">
        Almost There! Confirm Your Email to Start Your 7-Day Free Trial
        </h3>

        <p className=" text-center leading-loose">
        
 
          Your 7-day free trial of the Lawlyfy <span className="font-semibold">Silver Package</span> is ready to go. <br />
            
          Click the confirmation link in your email inbox and you’ll be good to go!
        </p>
      </section>
    </div>
  );
};

export default VerifyEmailAlert;
