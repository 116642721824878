import LandingHeader from "./(components)/header";
import MainSection from "./(components)/mainsection";
import Features from "./(components)/features";
import WakiliChat from "./(components)/wakilichat";
import DocRepo from "./(components)/docrepo";
import DocGen from "./(components)/docgen";
import ChatDocs from "./(components)/chatdocs";
import Pricing from "./(components)/pricing";
import Blog from "./(components)/blog";
import Footer from "./(components)/footer";

export default function Home() {
  return (
    <div>
      <LandingHeader />
      <main>
        <MainSection />
        <Features/>
        <WakiliChat/>
        <DocRepo/>
        <DocGen/>
        <ChatDocs/>
        <Pricing/>
        <Blog/>
        <Footer/>
      </main>
    </div>
  );
}
