import React from 'react';
import { useLocation } from 'react-router-dom'; 
import Logo from "../../../../../img/Logo2.jpg"

interface DefaultPromptsProps {
  prompts: string[];
  onPromptClick: (prompt: string) => void;
}

const DefaultPrompts: React.FC<DefaultPromptsProps> = ({ prompts, onPromptClick }) => {
  const location = useLocation(); 
  const shouldShowPrompts = !location.pathname.startsWith('/wakili/chatWithDoc');

  return (
    <div className="ChatView_Chats h-full w-[80vw]">

<div className="h-[80vh] mx-auto block flex items-center justify-center md:hidden mb-4">
        <img src={Logo} alt="Logo" className="w-48 h-auto" />
      </div>

      {shouldShowPrompts && prompts.map((prompt, index) => (
        <button
          key={index}
          className="ChatView_Chat_Prompt hidden md:flex text-xs lg:min-h-[180px] bg-gray-100 hover:bg-gray-200 transition-colors duration-300 rounded-lg p-3 text-left cursor-pointer"
          onClick={() => onPromptClick(prompt)}
        >
          {prompt}
        </button>
      ))}
    </div>
  );
};

export default DefaultPrompts;
