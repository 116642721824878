import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useGetChatCitations } from "../../../../data/chats/use-get-chat-citations";
import { Citations } from "../../../../data/chats/types";
import { useChat } from "../../../../context/chatContext";
import { useCollection } from "../../../../context/collectionContext";

interface SideCitationProps {
  setSelectedCitation: React.Dispatch<React.SetStateAction<Citations | null>>;
}

const SideBarCitation: React.FC<SideCitationProps> = ({
  setSelectedCitation,
}) => {
  const [selectedCitation, setSelectedCitationState] =
    useState<Citations | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { chatId, messageId } = useParams<{
    chatId: string;
    messageId?: string;
  }>();

  const {
    data: citations,
    isLoading,
    isError,
  } = useGetChatCitations(messageId, !!messageId);
  const { setSelectedChatId } = useChat();
  const { collectionValue, setCollectionValue } = useCollection();

  useEffect(() => {
    if (citations && citations.length > 0) {
      setSelectedCitation(citations[0]);
      setSelectedCitationState(citations[0]);
    }
  }, [citations, setSelectedCitation]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading citations. Try again later</div>;

  const handleClick = (citation: Citations) => {
    setSelectedCitation(citation);
    setSelectedCitationState(citation);
  };

  const goBack = () => {
    if (location.pathname.startsWith("/citations")) {
      if (location.pathname.startsWith("/citations/chatWithDoc")) {
        navigate(`/wakili/chatWithDoc/chat/${chatId}`);
        setCollectionValue(collectionValue)
      } else {
        navigate(`/wakili/chat/${chatId}`);
      }
    }
   // setSelectedChatId(chatId);
  };

  return (
    <section className="SideBar_Citation w-1/3 flex flex-col px-3">
      <div className="flex justify-between items-center  min-h-[10vh]">
        <h1 className="font-bold text-lg text-gray-700">Citations</h1>
        <button
          className="Citation_Button bg-gradient-to-r from-linearBlue1 to-linearBlue2  duration-300 hover:-translate-y-1 text-white font-bold py-2 px-4 md:px-8 rounded-full text-xs hover:bg-blue-500"
          onClick={goBack}
        >
          Back to Chat
        </button>
      </div>

      <div className="flex-grow py-3 mb-4 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {citations &&
            citations.map((citation, index) => (
              <div
                key={index}
                onClick={() => handleClick(citation)}
                className={`px-3 py-6 break-words rounded-2xl ${
                  selectedCitation === citation
                    ? "border-2 border-blue-700 font-semibold"
                    : "border border-blue-400"
                } hover:border-primary`}
              >
                <div className="w-full text-sm text-center leading-6">
                  {citation.file_name}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default SideBarCitation;
