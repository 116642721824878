import { useState } from "react";
import Logo2 from "../../img/Logo2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export default function LandingHeader() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <header>
      <nav>
        <div className="mx-auto  px-4 sm:px-6 lg:px-8 relative z-50 flex justify-between pt-6 max-w-7xl">
          <div className="relative z-10 flex items-center gap-16 ">
            <div className="flex items-center">
              <img
                src={Logo2}
                alt="Lawlify Logo"
                className="h-12 lg:h-14 mr-2"
              />
            </div>

            {/* the Nav Elements */}
            <div className="hidden lg:flex lg:gap-10 font-semibold">
              <a
                href="#about"
                className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300"
              >
                <span className="relative z-10">Features</span>
              </a>

              <a
                href="#pricing"
                className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300"
              >
                <span className="relative z-10">Pricing</span>
              </a>

              <a
                href="#about"
                className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300"
              >
                <span className="relative z-10">About us</span>
              </a>
              <a
                href="#blog"
                className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300"
              >
                <span className="relative z-10">Blog</span>
              </a>
              <a
                href="#contact"
                className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300"
              >
                <span className="relative z-10">Contact</span>
              </a>
            </div>
          </div>

          <div className="flex items-center gap-6">
            <div className="lg:hidden" data-headlessui-state>
              <button
                className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&amp;:not(:focus-visible)]:focus:outline-none"
                aria-label="Toggle site navigation"
                id="headlessui-popover-button-:r0:"
                type="button"
                aria-expanded={isOpen}
                data-headlessui-state={isOpen ? "open" : ""}
                onClick={toggleMenu}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  aria-hidden="true"
                  className="h-6 w-6"
                >
                  <path
                    d={isOpen ? "M17 14l-5-5-5 5" : "M5 6h14M5 18h14M5 12h14"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </button>

              {isOpen && (
                <div
                  className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                  id="headlessui-popover-overlay-:rn1:"
                  aria-hidden="true"
                  data-headlessui-state="open"
                  data-projection-id="37"
                  style={{ opacity: "1" }}
                ></div>
              )}

              {isOpen && (
                <div
                  className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                  id="headlessui-popover-panel-:r1:"
                  data-headlessui-state="open"
                  data-projection-id="38"
                  style={{ opacity: "1", transform: "none" }}
                >
                  <div className="space-y-4">
                    <a
                      className="block text-base leading-7 tracking-tight text-gray-700"
                      data-headlessui-state="open"
                      href="#about"
                    >
                      Features
                    </a>
                    <a
                      className="block text-base leading-7 tracking-tight text-gray-700"
                      data-headlessui-state="open"
                      href="#pricing"
                    >
                      Pricing
                    </a>
                    <a
                      className="block text-base leading-7 tracking-tight text-gray-700"
                      data-headlessui-state="open"
                      href="#about"
                    >
                      About Us
                    </a>
                    <a
                      className="block text-base leading-7 tracking-tight text-gray-700"
                      data-headlessui-state="open"
                      href="#blog"
                    >
                      Blog
                    </a>
                    <a
                      className="block text-base leading-7 tracking-tight text-gray-700"
                      data-headlessui-state="open"
                      href="#contact"
                    >
                      Contact
                    </a>
                  </div>

                  <div className="mt-8 flex flex-col gap-4">
                    <a
                      className="inline-flex justify-center rounded-full py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors text-white bg-gray-800 hover:bg-gray-900 active:bg-gray-800  active:text-white/80"
                      href="/login"
                    >
                      Sign in
                    </a>
                  </div>
                </div>
              )}
            </div>

            <a
              className="inline-flex hidden justify-center rounded-full py-2 px-3 text-xs font-semibold outline-2 outline-offset-2 transition-colors bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80 lg:block"
              href="/login"
            >
              Sign in
            </a>
            <FontAwesomeIcon icon={faUser} />
          </div>
        </div>
      </nav>
    </header>
  );
}
