import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DeleteDocument } from "./delete-document"; 

export function useDeleteDocument() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: DeleteDocument,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["documents"] });
    },
  });
}
