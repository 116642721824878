import Blog1 from "../../img/Blog1.jpg"
import Blog2 from "../../img/Blog2.jpg"
import Blog3 from "../../img/Blog3.jpg"
import Sure from "../../img/Sure.png"
import Wambui from "../../img/Wambui.png"

export default function Blog() {
  return (
    <section id="blog" className="py-10 sm:py-16">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl sm:text-5xl font-medium tracking-tight text-gray-900">
            Our Blog
          </h2>
          <p className="mt-4 text-sm text-gray-700 leading-loose">
            Explore the latest insights and developments in AI for legal
            professionals through our informative blog articles.
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-12 gap-y-10 sm:mt-20 lg:max-w-none lg:grid-cols-3">
         

          <a
            href="https://medium.com/@lawlyfy.ai/7-ways-ai-is-transforming-the-legal-field-in-kenya-1a3505f1ecd8"
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline"
          >
            <section className="flex flex-col overflow-hidden rounded-xl pb-5 pt-2 px-2 shadow-lg h-[350px] shadow-gray-900/5 bg-[#fbfbfb] border border-gray-200/55 hover:-translate-y-2 delay-150 duration-300 ease-in-out">
              <div className="flex-1 h-1/2 overflow-hidden">
                <img src={Blog1} alt="Blog 1" className="h-full w-full object-cover" />
              </div>

              <div className="py-2">
                <button className="rounded-full font-medium text-gray-800 bg-gray-300 text-[10px] w-1/4 py-1">
                  Tech
                </button>
              </div>

              <div className="flex">
                <h3 className="line-clamp-1 text-xs font-semibold text-gray-900 leading-loose hover:cursor-pointer">
                7 Ways AI is Transforming the Legal Field in Kenya
                </h3>
              </div>

              <p className="py-1 text-[11px] text-gray-700 leading-loose line-clamp-1">
              Recent advancements in artificial intelligence (AI) are transforming legal work as we know it, unlocking massive efficiency and productivity gains.
              </p>

              <div className="py-3 flex justify-start items-center">
                <div>
                  <img src={Sure} alt="Bayes" className="h-10 lg:h-12 mr-2 rounded-full" />
                </div>

                <div className="text-[10px] leading-loose font-medium">
                  <p className="font-semibold">Anthony Sure</p>
                  <p className="leading-loose text-gray-500">
                    September 10, 2024 <span className="font-bold">.</span>
                    <span> 2 min read</span>
                  </p>
                </div>
              </div>
            </section>
          </a>

          <a
            href="https://medium.com/@lawlyfy.ai/ethical-ai-use-in-legal-research-c8f592a5f481"
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline"
          >
            <section className="flex flex-col overflow-hidden rounded-xl pb-5 pt-2 px-2 shadow-lg h-[350px] shadow-gray-900/5 bg-[#fbfbfb] border border-gray-200/55 hover:-translate-y-2 delay-150 duration-300 ease-in-out">
              <div className="flex-1 h-1/2 overflow-hidden">
                <img src={Blog2} alt="Blog 2" className="h-full w-full object-cover" />
              </div>

              <div className="py-2">
                <button className="rounded-full font-medium text-gray-800 bg-gray-300 text-[10px] w-1/4 py-1">
                  Tech
                </button>
              </div>

              <div className="flex">
                <h3 className="line-clamp-1 text-xs font-semibold text-gray-900 leading-loose hover:cursor-pointer">
                Ethical AI Use in Legal Research
                </h3>
              </div>

              <p className="py-1 text-[11px] text-gray-700 leading-loose line-clamp-1">
              At Lawlyfy, we recognized from the very beginning that leveraging AI to enhance legal research would require a deep commitment to ethical
              </p>

              <div className="py-3 flex justify-start items-center">
                <div>
                  <img src={Sure} alt="Bayes" className="h-10 lg:h-12 mr-2 rounded-full" />
                </div>

                <div className="text-[10px] leading-loose font-medium">
                  <p className="font-semibold">Anthony Sure</p>
                  <p className="leading-loose text-gray-500">
                    September 13, 2024 <span className="font-bold">.</span>
                    <span> 4 min read</span>
                  </p>
                </div>
              </div>
            </section>
          </a>


          <a
            href="https://wambuimatingi.com/what-you-need-to-know-about-the-two-fold-protection-of-imagery-rights-in-kenya-2/"
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline"
          >
            <section className="flex flex-col overflow-hidden rounded-xl pb-5 pt-2 px-2 h-[350px] shadow-lg shadow-gray-900/5 bg-[#fbfbfb] border border-gray-200/55 hover:-translate-y-2 delay-150 duration-300 ease-in-out">
              <div className="flex-1 h-1/2 overflow-hidden">
                <img src={Blog3} alt="Blog 3" className="h-full w-full object-cover" />
              </div>

              <div className="py-2">
                <button className="rounded-full font-medium text-gray-800 bg-gray-300 text-[10px] w-1/4 py-1">
                  Legal
                </button>
              </div>

              <div className="flex">
                <h3 className="line-clamp-1 text-xs font-semibold text-gray-900 leading-loose hover:cursor-pointer">
                  The two-fold protection of legal rights in Kenya
                </h3>
              </div>

              <p className="py-1 text-[11px] text-gray-700 leading-loose line-clamp-1">
                Observations, criticisms and critiques over a lacunae in the protection of photographs
              </p>

              <div className="py-3 flex justify-start items-center">
                <div>
                <img src={Wambui} alt="Bayes" className="h-10 lg:h-12 mr-2 rounded-full" />
                </div>

                <div className="text-[10px] leading-loose font-medium">
                  <p className="font-semibold">Wambui Matingi</p>
                  <p className="leading-loose text-gray-500">
                    March 25, 2024 <span className="font-bold">.</span>
                    <span> 3 min read</span>
                  </p>
                </div>
              </div>
            </section>
          </a>



        </div>
      </div>
    </section>
  )
}