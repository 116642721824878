import { useRegisterForm } from "./(components)/registerForm";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Register(){
    const { formik, err, loading } = useRegisterForm();
    return(
        <div className=" bg-slate-50 h-screen pt-10 mx-auto max-w-7xl">
      <form
        onSubmit={formik.handleSubmit}
        className="w-[90vw] md:w-[30vw] text-[#615f5f] rounded-2xl bg-white m-auto h-auto px-8 py-6"
      >
        <h1 className="text-center text-xl font-bold leading-6 mb-2">
          Sign Up
        </h1>
        <p className="text-center text-sm font-semibold leading-6 mb-4">
          Let's get started on Lawlyfy
        </p>
        {err && <p className="text-xs text-red-600 mt-1">{err}</p>}
        <div className="mb-4">
          <label className="block text-xs font-bold mb-2">
            Name
          </label>
          <input
            required
            type="text"
            placeholder="Name"
            name="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Name}
            className="shadow-sm appearance-none text-xs border rounded w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.Name && formik.errors.Name ? (
            <div className="text-xs pt-1 text-red-600">
              {formik.errors.Name}
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <label className="block text-xs font-bold mb-2">
            Email
          </label>
          <input
            required
            type="email"
            placeholder="email"
            name="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Email}
            className="shadow-sm appearance-none text-xs border rounded w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.Email && formik.errors.Email ? (
            <div className="text-xs pt-1 text-red-600">
              {formik.errors.Email}
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <label className="block text-xs font-bold mb-2">
            Password
          </label>
          <input
            required
            type="password"
            placeholder="password"
            name="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Password}
            className="shadow-sm appearance-none text-xs border rounded w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
          />

          {formik.touched.Password && formik.errors.Password ? (
            <div className="text-xs pt-1 text-red-600">
              {formik.errors.Password}
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <label className="block text-xs font-bold mb-2" >
            Confirm Password
          </label>
          <input
            required
            type="password"
            placeholder="confirm password"
            name="confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            className="shadow-sm appearance-none text-xs border rounded w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="text-xs pt-1 text-red-600">
              {formik.errors.confirmPassword}
            </div>
          ) : null}
        </div>

        <div className="flex flex-col">
          <button className="flex items-center justify-center bg-gradient-to-r from-linearBlue1 to-linearBlue2 mb-4 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          {loading ? (
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
            ) : (
              <>
               Register
              </>
            )}
          </button>
        </div>

        <div className="text-xs text-center">
          Already have an account?{" "}
          <Link to="/login" className="text-blue-700 underline font-bold">
            Login
          </Link>
        </div>
      </form>
    </div>
    )
}