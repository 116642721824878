import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from "../../components/layout/sidebar/index";
import MainBar from "../../components/layout/mainbar/index";
import { useAuth } from '../../context/useAuth';
import { SkeletonLoading } from "../../components/common/skeleton";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Wakili(){
    const navigate = useNavigate();
    const { role } = useAuth();
    
    useEffect(() => {
        const token = localStorage.getItem('token');
       if (token === '') {
          navigate('/login');
        }
      }, []);
    
     
      return (
        <div className="min-h-screen flex mx-auto w-full max-w-full overflow-hidden">
          <SideBar />
          <MainBar />
          <ToastContainer />
        </div>
      );
      
}