import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { validationSchema } from "./validation";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const useResetPasswordForm = () => {

 
  const [err, setError] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { token } = useParams();
 

  const formik = useFormik({
    initialValues: {
      Password: "",
      ConfirmPassword:"",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setLoading(true);
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/v1/auth/resetPassword/${token}`,
          {
            Password: values.Password,
          }
        );

        resetForm();

        toast.info(
          "You have successfully reset your password, you can now log in",
          {
            position: "top-right",
            autoClose: 1500,
            containerId: "toast-container-success",
          }
        );
        

        navigate("/");
        
      } catch (error: any) {
      
        setError("Error resetting password. Please try again later.");

        toast.error("Error Resetting Password. Try Later", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          containerId: "toast-container-reset-failure",
        });

      
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return { formik, err, loading };
};
