import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Star from '../../../../../img/Star.png';

interface WelcomeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ isOpen, onClose }) => {
  return (
    <Transition show={isOpen}>
      <Dialog
        onClose={onClose}
        className="fixed ml-12 inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-30"
            leave="ease-in duration-200"
            leaveFrom="opacity-30"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative bg-white p-6 rounded-lg max-w-xl mx-auto">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-white rounded-xs text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-linearBlue1"
                  onClick={onClose}
                >
                  <FontAwesomeIcon icon={faClose} />
                  <span className="sr-only">Close</span>
                </button>
              </div>
              <Dialog.Title className="text-xl text-center font-bold mb-4">
                Welcome to Lawlyfy
              </Dialog.Title>
              <Dialog.Description>
                <p className="text-center mb-6 text-sm">
                  Your one-stop-shop for all your legal research needs. Lawlyfy offers:
                </p>
                <ul className="space-y-4 text-sm">
                  <li className="flex items-center space-x-2">
                    <img src={Star} alt="Star" className="h-4 w-4 mt-1" />
                    <div>
                      <span className="font-semibold text-linearBlue1">Wakili Chat</span> for
                      general research and strategy formulation
                    </div>
                  </li>
                  <li className="flex items-center space-x-2">
                    <img src={Star} alt="Star" className="h-4 w-4 mt-1" />
                    <div>
                      <span className="font-semibold text-linearBlue1">Chat With My Docs</span>{" "}
                      to harness your personal data and documents for research
                    </div>
                  </li>
                  <li className="flex items-center space-x-2">
                    <img src={Star} alt="Star" className="h-4 w-4 mt-1" />
                    <div>
                      <span className="font-semibold text-linearBlue1">Kenya Law Reports</span>{" "}
                      to seamlessly search across Kenya's case law, the Kenya
                      gazette, laws of Kenya, and the cause list
                    </div>
                  </li>
                  <li className="flex items-center space-x-2">
                    <img src={Star} alt="Star" className="h-4 w-4 mt-1" />
                    <div>
                      <span className="font-semibold text-linearBlue1">AI Doc Drafter</span> to
                      draft documents adhering to your templates
                    </div>
                  </li>
                </ul>
              </Dialog.Description>
              <div className="flex justify-center mt-6">
                <button
                  onClick={onClose}
                  className=" bg-gradient-to-r from-linearBlue1 to-linearBlue2 text-white px-4 py-2 rounded-lg"
                >
                  Get Started
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default WelcomeModal;