import { useQuery } from "@tanstack/react-query";
import { fetchCollections } from "./get-collections";
import { Collection } from "./types";


export function useGetCollections() {
    return useQuery<Collection[] | null, Error>({  
      queryKey: ["collections"],
      queryFn: () => fetchCollections(),
    });
  }

  
  
  