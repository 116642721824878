import { useQuery } from "@tanstack/react-query";
import { fetchDocuments } from "./get-documents";
import { Document} from "./types";


export function useGetDocuments() {
    return useQuery<Document[], Error>({  // Change the return type to Student[]
      queryKey: ["documents"],
      queryFn: () => fetchDocuments(),
    });
  }
  