

import { useQuery } from "@tanstack/react-query";
import { useFetchChatMessages } from "./fetch-chat-messages";
import { Messages } from "./types";



export function useGetChatMessages(chatId: string | undefined, enabled: boolean) {
  const fetchChatMessages = useFetchChatMessages(chatId);

  return useQuery<Messages[], Error>({
    queryKey: ["messages", chatId],
    queryFn: fetchChatMessages,
    enabled: enabled
  });
}
