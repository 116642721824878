import ComingSoon from "../../../../img/Citations.png";

const DocDrafter = () => {
    return (
        <>
            <div className=' min-h-[85vh] flex justify-center items-center bg-gray-200 '>
                <img src={ComingSoon} alt="coming soom" />
            </div>
        </>
    );
};

export default DocDrafter;