import { createContext, useContext, useState } from "react";

// 1. Create the context
const InputContext = createContext();

// 2. Create a context provider
export const InputProvider = ({ children }) => {
  const [inputValueContext, setInputValueContext] = useState("");

  return (
    <InputContext.Provider value={{ inputValueContext, setInputValueContext }}>
      {children}
    </InputContext.Provider>
  );
};

// 3. Create a custom hook to use the context
export const useInput = () => {
  const context = useContext(InputContext);
  if (!context) {
    throw new Error("useInput must be used within an InputProvider");
  }
  return context;
};
