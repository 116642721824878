import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { loginAPI } from '../../utils/api';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../context/useAuth';
import ErrorMessage from "../../components/common/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface FormValues {
  Email: string;
  Password: string;
  RememberMe: boolean;
}

const validationSchema = Yup.object({
  Email: Yup.string().email('Invalid email address').required('Required'),
  Password: Yup.string().required('Required'),
  RememberMe: Yup.boolean()
});

export default function Login() {
  const { login } = useAuth()
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      Email: '',
      Password: '',
      RememberMe: false
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data } = await loginAPI(values);

        const { user, token } = data;
        const { _id: userId, Role } = user;

        // Store the entire token
        localStorage.setItem('token', token);

        if (Role === 'admin'){
          navigate('/wakili/admin')
        }else{
          const { from = '/wakili', plan = null } = location.state || {};
          navigate(plan ? '/checkout' : from, { state: { plan } });
        }
        
      } catch (error:any) {
        if (error.response) {
          
          if (error.response.status === 404) {
         
            setErrorMessage('User does not exist. Please sign up.');
          } else if (error.response.status === 403) {
        
            setErrorMessage('Account not verified. Please verify your email.');
          } else if (error.response.status === 401) {
        
            setErrorMessage('Invalid username or password.');
          } else {
        
            setErrorMessage(`Error: ${error.response.data.error}`);
          }
        } else if (error.request) {
      
          setErrorMessage('No response received from server.');
        } else {
        
          setErrorMessage('Error occurred while processing the request.');
        }
      }finally{
        setLoading(false)
      }
    },
  });

  return (
    <div className="bg-slate-50 h-screen pt-10 mx-auto max-w-7xl">
      
      <form
        onSubmit={formik.handleSubmit}
        className="w-[90vw] md:w-1/3 rounded-2xl bg-white m-auto h-auto px-8 py-6"
      >
       
       <h1 className="text-center text-xl font-bold leading-6 mb-2 text-[#615f5f]">
          Sign In
        </h1>
        <p className="text-center text-sm leading-6 font-semibold mb-4 text-[#615f5f]">
          Welcome back to Lawlyfy
        </p>

        <div className="mb-4">
          <label
            className="block text-[#615f5f] text-xs font-bold mb-2"
            htmlFor="Email"
          >
            Email
          </label>
          <input
            type="email"
            name="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Email}
            className="shadow-sm appearance-none text-xs border rounded w-full py-3 px-3 text-[#615f5f] leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.Email && formik.errors.Email && (
            <div className="text-xs pt-1 text-red-600">
              {formik.errors.Email}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-[#615f5f] text-xs font-bold mb-2"
            htmlFor="Password"
          >
            Password
          </label>
          <input
            type="password"
            name="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Password}
            className="shadow-sm appearance-none text-xs border rounded w-full py-3 px-3 text-[#615f5f] leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.Password && formik.errors.Password && (
            <div className="text-xs pt-1 text-red-600">
              {formik.errors.Password}
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex md:flex-row  justify-between mb-4">
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              id="rememberMe"
              name="RememberMe"
              checked={formik.values.RememberMe}
              onChange={formik.handleChange}
              className="rounded border-gray-300 mr-2"
            />
            <label
              className="block text-[#615f5f] text-xs font-bold"
              htmlFor="RememberMe"
            >
              Remember Me
            </label>
          </div>
          <Link
            to="/forgetPassword"
            className="text-blue-700 font-bold text-xs pointer hover:underline"
          >
            Forgot Password
          </Link>
        </div>

        <div className="flex flex-col">
          <button
            type="submit"
            className="bg-gradient-to-r from-linearBlue1 to-linearBlue2  mb-4 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
             {loading ? (
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
            ) : (
              <>
               Log In
              </>
            )}
          </button>
        </div>

        {errorMessage && <ErrorMessage message={errorMessage} />}
        <div className="text-xs text-center">
          Don't have an account?{" "}
          <Link to="/register" className="text-blue-700 underline font-bold">
            Register
          </Link>
        </div>
      </form>

      <ToastContainer containerId="verification-success" />
    </div>
  );
}