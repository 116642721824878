import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchCases } from './fetch-cases';
import { CaselawSearchParams, CaselawDocument } from './types';

export const useFetchCases = (searchParams: CaselawSearchParams): UseQueryResult<CaselawDocument[], Error> => {
  return useQuery<CaselawDocument[], Error>({
    queryKey: ['cases', searchParams],
    queryFn: () => fetchCases(searchParams),
    enabled: Object.values(searchParams).some(param => param !== undefined && param !== ''),
  });
};