
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useCreateChat from "./createNewChat";
import useCreateChatDefault from "./createNewChatDefault";

export function useUpdateChats() {
  const queryClient = useQueryClient();
  const createChat = useCreateChat();

  return useMutation({
    mutationFn: createChat, 
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chats"] });
    },
  });
}


export function useUpdateChatsDefault() {
    const queryClient = useQueryClient();
    const createChat = useCreateChatDefault();
  
    return useMutation({
      mutationFn: createChat, 
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["chats"] });
      },
    });
  }
  
