
import { useNavigate } from 'react-router-dom';
import Rainbow from "../../img/Rainbow.jpg"
import { useFetchSingleUser } from '../../data/users/use-fetch-single-user';


export default function Pricing(){
  const navigate = useNavigate();
  const userId = localStorage.getItem("token");
  console.log('The token is', userId);

  const { data: user} = useFetchSingleUser();
  const handleSubscribe = (plan: string) => {
    if (user?.email) { 
      navigate('/checkout', { state: { plan, email: user.email } });
    } else {
      navigate('/login', { state: { from: '/pricing', plan } });
    }
  };
  

    return(
        <section id="pricing" className="py-10 sm:py-16">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl sm:text-5xl font-medium tracking-tight text-gray-900">
              Simple, Easy Pricing
            </h2>
            <p className="mt-4 text-sm text-gray-700 leading-loose">
              Affordable packages tailored to your research needs
            </p>
          </div>

          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:mt-20 lg:max-w-none lg:grid-cols-3">
            <div className="Border_Gradient rounded-3xl p-[1px]">
              <section className="flex flex-col overflow-hidden rounded-3xl p-6 shadow-lg shadow-gray-900/5 bg-white">
                <h3 className="flex items-center text-sm font-semibold text-gray-900">
                  SILVER PACKAGE
                </h3>
                <p className="mt-5 flex items-center text-3xl tracking-tight text-gray-900">
                  ksh 1300{" "}
                  <span className="tracking-wide text-xs font-thin text-gray-700">
                    /month
                  </span>
                </p>
                <p className="py-3 text-xs text-gray-700 leading-loose ">
                  All the basic features to meet your essential legal research
                  needs with ease and efficiency.{" "}
                </p>

                <div className="border-b mb-4"></div>
                <div className="order-last mt-6">
                  <ul className="-my-2 text-xs text-gray-700">
                    <li className="flex py-2">
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-[#00C8FF]"
                      >
                        <path
                          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                          fill="currentColor"
                        ></path>
                        <circle
                          cx="12"
                          cy="12"
                          r="8.25"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                      </svg>
                      <span className="ml-4"><span className='font-semibold'>Semantic AI :</span> Up to 500 Queries</span>
                    </li>
                    <li className="flex py-2">
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-[#00C8FF]"
                      >
                        <path
                          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                          fill="currentColor"
                        ></path>
                        <circle
                          cx="12"
                          cy="12"
                          r="8.25"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                      </svg>
                      <span className="ml-4"><span className='font-semibold'>Keyword Search :</span> Unlimited Searches</span>
                    </li>
                    <li className="flex py-2">
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-[#00C8FF]"
                      >
                        <path
                          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                          fill="currentColor"
                        ></path>
                        <circle
                          cx="12"
                          cy="12"
                          r="8.25"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                      </svg>
                      <span className="ml-4"><span className='font-semibold'>WakiliChat :</span> 20 Chats + Citations</span>
                    </li>
                    <li className="flex py-2 text-white">
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-white"
                      >
                        <path
                          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                          fill="currentColor"
                        ></path>
                        <circle
                          cx="12"
                          cy="12"
                          r="8.25"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                      </svg>
                      <span className="ml-4">20 Chats + Citations for LawlifyChat </span>
                    </li>
                   
                  </ul>

                  <div className="Border_Gradient p-[1px] flex mt-6 ">
                    <button
                      className="inline-flex w-full justify-center py-2 px-3 text-xs font-semibold outline-2 outline-offset-2 transition-colors bg-white text-gray-800 hover:text-white hover:bg-gray-900/25 delay-150 duration-300 ease-in-out"
                      type="submit"
                      aria-label="Get 14 Days Free Trial Button"
                      onClick={() => handleSubscribe('Silver')}
                    >
                      Subscribe to the Silver Plan
                    </button>
                  </div>
                </div>
              </section>
            </div>

            <div className="Border_Gradient rounded-3xl p-[1px]">
              <section className="flex flex-col overflow-hidden rounded-3xl p-6 shadow-lg shadow-gray-900/5 border-4 border-transparent bg-white">
                <div className="relative z-10">
                  <h3 className=" flex items-center text-sm font-semibold text-gray-900">
                    GOLD PACKAGE
                  </h3>
                  <p className="mt-5 flex  items-center text-3xl tracking-tight text-gray-900">
                    Kshs 1560{" "}
                    <span className="tracking-wide text-xs font-thin text-gray-700">
                      /month
                    </span>
                  </p>
                  <p className="py-3 text-xs text-gray-700 leading-loose">
                    Customizable add-on documents to handle more complex cases with precision.
                  </p>

                  <div className="border-b mb-4"></div>
                  <div className="order-last mt-8">
                    <ul role="list" className="-my-2 text-xs text-gray-700">
                    <li className="flex py-2">
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-[#00C8FF]"
                      >
                        <path
                          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                          fill="currentColor"
                        ></path>
                        <circle
                          cx="12"
                          cy="12"
                          r="8.25"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                      </svg>
                      <span className="ml-4"><span className='font-semibold'>Semantic AI :</span> Up to 500 Queries</span>
                    </li>
                    <li className="flex py-2">
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-[#00C8FF]"
                      >
                        <path
                          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                          fill="currentColor"
                        ></path>
                        <circle
                          cx="12"
                          cy="12"
                          r="8.25"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                      </svg>
                      <span className="ml-4"><span className='font-semibold'>Keyword Search : </span> Unlimited Searches</span>
                    </li>
                      <li className="flex py-2 mb-10">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          className="h-5 w-5 flex-none text-[#00C8FF]"
                        >
                          <path
                            d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                            fill="currentColor"
                          ></path>
                          <circle
                            cx="12"
                            cy="12"
                            r="8.25"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></circle>
                        </svg>
                        <span className="ml-4"><span className='font-semibold'>Chat With My Docs : </span>20 Chats + Citations</span>
                      </li>
                     
                   
                    </ul>
                    <button
                      className="inline-flex w-full justify-center py-2 px-3 text-xs font-semibold outline-2 outline-offset-2 transition-colors bg-gray-800 text-white hover:bg-gradient-to-tr from-[#6ddcff] to-[#7f60f9] active:bg-gray-800 active:text-white/80 mt-6 delay-150 duration-300 ease-in-out "
                      type="submit"
                      aria-label="Get 14 Days Free Trial Button"
                      onClick={() => handleSubscribe('Gold')}
                    >
                        Subscribe to the Gold Plan
                    </button>
                  </div>
                </div>
                <div className="absolute max-w-[370px] max-h-[500px] opacity-20 z-0">
                  <img
                    src={Rainbow}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              </section>
            </div>

            <div className="Border_Gradient rounded-3xl p-[1px]">
              <section className="flex flex-col overflow-hidden rounded-3xl p-6 shadow-lg shadow-gray-900/5 bg-white">
                <h3 className="flex items-center text-sm font-semibold text-gray-900">
                  PLATINUM PACKAGE
                </h3>
                <p className="mt-5 flex items-center text-3xl tracking-tight text-gray-900">
                  ksh 1950{" "}
                  <span className="tracking-wide text-xs font-thin text-gray-700">
                    /month
                  </span>
                </p>
                <p className="py-3 text-xs text-gray-700 leading-loose min-h-3">
                All the advanced features to elevate your legal research to the highest level.
                </p>

                <div className="border-b mb-4"></div>
                <div className="order-last mt-6">
                  <ul role="list" className="-my-2 text-xs text-gray-700">
                  <li className="flex py-2">
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-[#00C8FF]"
                      >
                        <path
                          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                          fill="currentColor"
                        ></path>
                        <circle
                          cx="12"
                          cy="12"
                          r="8.25"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                      </svg>
                      <span className="ml-4"><span className='font-semibold'>Semantic AI :</span> Up to 500 Queries</span>
                    </li>
                    <li className="flex py-2">
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-[#00C8FF]"
                      >
                        <path
                          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                          fill="currentColor"
                        ></path>
                        <circle
                          cx="12"
                          cy="12"
                          r="8.25"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                      </svg>
                      <span className="ml-4"><span className='font-semibold'>Keyword Search : </span> Unlimited Searches</span>
                    </li>
                    <li className="flex py-2">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          className="h-5 w-5 flex-none text-[#00C8FF]"
                        >
                          <path
                            d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                            fill="currentColor"
                          ></path>
                          <circle
                            cx="12"
                            cy="12"
                            r="8.25"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></circle>
                        </svg>
                        <span className="ml-4"><span className='font-semibold'>Wakili Chat : </span>20 Chats + Citations </span>
                      </li>
                      <li className="flex py-2">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          className="h-5 w-5 flex-none text-[#00C8FF]"
                        >
                          <path
                            d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                            fill="currentColor"
                          ></path>
                          <circle
                            cx="12"
                            cy="12"
                            r="8.25"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></circle>
                        </svg>
                        <span className="ml-4"><span className='font-semibold'>Chat With My Docs : </span>20 Chats + Citations</span>
                      </li>
                   
                  </ul>

                  <div className="Border_Gradient p-[1px] flex mt-6 ">
                    <button
                      className="inline-flex w-full justify-center py-2 px-3 text-xs font-semibold outline-2 outline-offset-2 transition-colors bg-white text-gray-800 hover:text-white hover:bg-gray-900/25 delay-150 duration-300 ease-in-out"
                      type="submit"
                      aria-label="Get 14 Days Free Trial Button"
                      onClick={() => handleSubscribe('Platinum')}
                    >
                      Subscribe to the Platinum Plan
                    </button>
                  </div>
                </div>
              </section>
            </div>

           
          </div>
        </div>
      </section>
    )
}