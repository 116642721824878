import React from "react";
import { useParams } from "react-router-dom";
import { useFetchSingleUser } from "../../../../../../data/users/use-fetch-single-user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const User: React.FC = () => {
    // Get the userId from the URL
    const { userId } = useParams<{ userId: string }>();

    // Fetch user data using the fetched userId
    const { data: user, isLoading, error } = useFetchSingleUser();
    const navigate = useNavigate();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className ='min-h-screen overflow-y-scroll'>
            <div className='h-[83vh] bg-blue-100 rounded-3xl p-8 my-4 text-xs'>
                <h1 className='font-bold text-center text-2xl mb-2'>Manage User</h1>
                {user ? (
                    <div className ='flex flex-col md:flex-row justify-between gap-2'>
                    {/*<div className='w-1/3 bg-gray-100 rounded-lg'>

                    </div>*/}
                    <div className='space-y-4 w-2/3 my-2 md:my-4'>
                        <div className='space-y-4 px-4'>
                            <p><span className='font-semibold'>Name: </span>{user.name}</p>
                            <p><span className='font-semibold'>Email: </span>{user.email}</p>
                            <p><span className='font-semibold'>Subscription Status: </span>{user.subscriptionStatus}</p>
                            <p><span className='font-semibold'>Joined: </span>{new Date(user.joined).toLocaleDateString()}</p>
                            <p><span className='font-semibold'>Last Used: </span>{user.lastUsed ? new Date(user.lastUsed).toLocaleDateString() : new Date(user.joined).toLocaleDateString()}</p>
                        </div>
                        <div className='w-4/5 shadow-md py-6 rounded-3xl px-4'>
                            <div className='space-y-3'>

                                <div className='flex justify-between'>
                                    <p className='font-semibold'>Lawlyfy Chat #: </p>
                                    <p>{user.nonSpecificChatMessages} / 20</p>
                                </div>

                                <div className='relative w-full h-4 bg-white rounded-full overflow-hidden'>
                                    <div
                                        className='absolute top-0 left-0 h-full bg-blue-700'
                                        style={{ width: `${(user.nonSpecificChatMessages / 20) * 100}%` }}
                                    ></div>
                                </div>

                            </div>

                            <div className='space-y-3 mt-8'>

                                <div className='flex justify-between'>
                                    <p className='font-semibold'>Chat With My Docs #: </p>
                                    <p>{user.specificChatMessages} / 30</p>
                                </div>

                                <div className='relative w-full h-4 bg-white rounded-full overflow-hidden'>
                                    <div
                                        className='absolute top-0 left-0 h-full bg-blue-700'
                                        style={{ width: `${(user.specificChatMessages / 30) * 100}%` }}
                                    ></div>
                                </div>

                            </div>

                        </div>

                    
                        <button 
                            className="mt-4 py-2 px-4 hover:text-blue-500 duration-300"
                            onClick={() => navigate(`/wakili/admin`)}
                            >

                        <FontAwesomeIcon icon={faArrowAltCircleLeft} className="inline mr-2"/> Back
                        </button>
                       
                    </div>
                    </div>
                ) : (
                    <div>No user data found</div>
                )}
            </div>
        </div>
    );
};

export default User;
