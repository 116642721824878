import axios from 'axios';
import { REACT_APP_BACKEND_URL } from "../constants";

export const createOrder = async (orderData: any) => {
  const response = await axios.post(`${REACT_APP_BACKEND_URL}/v1/pesapal/submit-order`, orderData);
  return response.data;
};




export const getTransactionStatus = async () => {
  const token = localStorage.getItem('token'); 

if (token === null) {
  console.error("User is not logged in.");  
  return null; 
}
  try {
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/v1/pesapal/transaction-status`, {
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json'  
      }
    });
    return response.data;
  } catch (error) {
    console.log('Error fetching transaction status:', error);
    throw error; 
  }
};

  