import React from 'react';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => (
  <div className="text-xs pb-1 text-center text-red-600">{message}</div>
);

export default ErrorMessage;