import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { REACT_APP_BACKEND_URL} from "../constants";
interface MessageData {
  chatId: string | undefined;
  promptText: string;
  response: string;
  citations: string[];
}

const useSendDataToDatabase = () => {
  const queryClient = useQueryClient();

  const sendDataToDatabase = async (messageData: MessageData) => {
    try {
      await axios.post(`${REACT_APP_BACKEND_URL}/v1/message/createMessage`, messageData);
      queryClient.invalidateQueries({ queryKey: ['messages', messageData.chatId] });
    } catch (error) {
      console.error('Error sending data to the database:', error);
    }
  };

  return { sendDataToDatabase };
};

export default useSendDataToDatabase;