
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForgetPasswordForm } from './(components)/forgetPasswordForm';


export default function ForgetPassword(){
    const { formik, err, loading } = useForgetPasswordForm();
    return(
        <div className="bg-slate-50 h-screen pt-10 mx-auto max-w-7xl">
  
        <form onSubmit={formik.handleSubmit} className="w-[30vw] rounded-2xl bg-white m-auto h-auto px-8 py-6">
            <h1 className="text-center text-xl font-bold leading-6 mb-2 text-[#615f5f]">Forgot Password?</h1>
            <p className="text-center text-sm leading-6 font-semibold mb-4 text-[#615f5f]">Enter the email address associated with your account</p>


            {err && <p className="text-xs text-red-600 mt-1">{err}</p>}
        
            <div className="mb-4">
            <label className="block text-[#615f5f] text-xs font-bold mb-2" htmlFor="Email">Email</label>
            <input
                type="email"
                name="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Email}
                className="shadow-sm appearance-none text-xs border rounded w-full py-3 px-3 text-[#615f5f] leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.Email && formik.errors.Email && <div className="text-xs pt-1 text-red-600">{formik.errors.Email}</div>}
            </div>
          

            <div className="flex flex-col">
                <button type="submit" className="bg-gradient-to-r from-linearBlue1 to-linearBlue2  mb-4 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    {loading ? <div className='loader'></div> : 'Submit'}
                </button>
            </div>
        
        </form>
        
    <ToastContainer/>
  
    </div>
    )
}