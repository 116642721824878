import ChatHeader from "./ChatHeader"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload,  faArrowLeft} from '@fortawesome/free-solid-svg-icons';

const CitationRepo = () =>{
    return(
        <main className ='DocRepo_Section'>
            <ChatHeader/>  
            <div className ='DocRepo_Section_Header '>
                <div>
                    <Link>
                        <button className ='Back_Button'>
                            <FontAwesomeIcon icon={faArrowLeft} className ='mr-3'/>
                            Back to search results
                        </button>
                    </Link>
                </div>

                <div className='Search_Buttons_Container text-sm font-semibold '>
                   <button className='search-button active'>
                       Download
                   </button>

                   <button className='search-button'>
                       PDF Download 
                       <FontAwesomeIcon icon={faDownload} className ='ml-3'/>
                   </button>
                </div>
                
            </div>

            <div className = 'mx-10 mt-6'>
                <div className ='font-semibold '>
                    <button className="text-left px-3 py-1">
                        Marita & Another V Republic (Criminal Appeal 007 & 006 Of [2023] (Consolidated) [2024] KEHC [1925] (KLR) (1 March 2024) (Judgment)
                    </button>      
                </div>

                <iframe src="" frameborder="0"></iframe>
            </div>
        </main>
    )
}

export default CitationRepo; 