import axios from "axios";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faUser,
  faChevronDown,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCollection } from "../../../context/collectionContext";
import { useAuth } from "../../../context/useAuth";

interface HeaderProps extends React.ComponentPropsWithoutRef<"header"> {
  title?: string;
  showDownloadFilter?: boolean;
}

export default function Header({
  title,
  showDownloadFilter = false,
}: HeaderProps) {
  const location = useLocation();
  const { logout } = useAuth();
  const { collectionValue, setCollectionValue } = useCollection();

  const [showOptions, setShowOptions] = useState(true);

  const toggleOptions = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
  };

  const getDefaultOption = () => {
    const currentPath = location.pathname;
    if (
      currentPath === "/wakili/chatWithDoc" ||
      currentPath.startsWith("/wakili/chatWithDoc/chat/")
    ) {
      return "Chat With My Docs";
    } else if (currentPath === "/wakili/docRepo") {
      return "My Documents";
    } else if (currentPath === "/wakili/addDoc") {
      return "Add A Document";
    }
    return "";
  };
  const defaultOption = getDefaultOption();
  const otherOptions = [
    { path: "/wakili/docRepo", label: "My Documents", collection: collectionValue },
    { path: "/wakili/addDoc", label: "Add A Document", collection: collectionValue },
    { path: "/wakili/chatWithDoc", label: "Chat With My Docs", collection: collectionValue },
  ];

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/logout`);
      localStorage.removeItem('token');
      logout();
      localStorage.setItem("hasSeenWakiliModal", "false");
      navigate("/");
    } catch (error) {
      localStorage.setItem("hasSeenWakiliModal", "false");
      navigate("/");
    }
  };
  return (
    <div>
      <div className="h-auto md:h-[12vh]  flex justify-between items-center">
        <div>
          <h1 className="font-semibold hidden md:block text-xl">{title}</h1>
        </div>

        <div className="w-auto md:w-1/3 ChatView_Icons_Container">
          {showDownloadFilter && (
            <div className="relative text-xs">
              <div
                className="flex items-center justify-between Medium_Shade text-white font-semibold py-2 px-4 rounded-full cursor-pointer"
                onClick={toggleOptions}
              >
                {defaultOption || "Chat With My Docs"}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`ml-2 transition-transform ${
                    showOptions ? "rotate-180" : ""
                  }`}
                />
              </div>
              {showOptions && (
                <div className="absolute top-12 left-0 bg-white border shadow-lg rounded-md py-2 px-4 z-20">
                  {otherOptions
                    .filter((option) => option.path !== location.pathname)
                    .map((option) => (
                      <Link
                        key={option.path}
                        to={option.path}
                        className="block text-blue-600 hover:text-blue-800 transition-colors duration-300 mb-2"
                      >
                        {option.label}
                      </Link>
                    ))}
                </div>
              )}
            </div>
          )}
          <div className="py-2 flex items-center gap-x-3 ">
          <div>
            <Link to="/wakili/profile">
              <FontAwesomeIcon icon={faUser} />
            </Link>
          </div>
            <div>
              
              <FontAwesomeIcon icon={faBell}/>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faRightFromBracket}
                onClick={handleLogout}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
