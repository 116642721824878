import React, { useState, useEffect } from 'react';
import { getTransactionStatus } from '../../data/pesapal/api';
import { Box, Typography, Paper, CircularProgress, Grid, Button } from '@mui/material';

interface TransactionStatus {
  payment_method: string;
  amount: number;
  created_date: string;
  confirmation_code: string;
  payment_status_description: string;
  description: string;
  message: string;
  payment_account: string;
  call_back_url: string;
  status_code: number;
  merchant_reference: string;
  payment_status_code: string;
  currency: string;
  error?: {
    error_type: string | null;
    code: string | null;
    message: string | null;
    call_back_url: string | null;
  };
  status: string;
}

export default function Payment() {
  const [status, setStatus] = useState<TransactionStatus | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTransactionStatus = async () => {
      try {
        const transactionStatus: TransactionStatus = await getTransactionStatus();
        setStatus(transactionStatus);
      } catch (err) {
        setError('Failed to fetch transaction status. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchTransactionStatus();
  }, []);

  return (
    <Box sx={{ padding: 4, maxWidth: '800px', margin: 'auto' }}>
      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 8 }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Checking transaction status...
          </Typography>
        </Box>
      ) : error ? (
        <Paper sx={{ padding: 4, textAlign: 'center', backgroundColor: '#fdecea' }}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
          <Button variant="contained" sx={{ mt: 2 }} onClick={() => window.location.href = '/'}>
            Retry
          </Button>
        </Paper>
      ) : (
        <Paper sx={{ padding: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Transaction Status
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Payment Method:</strong> {status?.payment_method || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Amount:</strong> KES {status?.amount}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Status Description:</strong> Completed </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Message:</strong> {status?.message}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Confirmation Code:</strong> {status?.confirmation_code || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Merchant Reference:</strong> {status?.merchant_reference || 'N/A'}</Typography>
            </Grid>
          {/* <Grid item xs={12}>
              <Typography variant="body1" color={status?.payment_status_description === 'Completed' ? 'green' : 'red'}>
                <strong>Status Code:</strong> {status?.status_code}
              </Typography>
            </Grid>*/}

            <Grid item xs ={12}>
              <Button variant="contained" sx={{ mt: 2 }} onClick={() => window.location.href = '/wakili'}>
              Back to Lawlify
            </Button>
            </Grid>

          
          </Grid>
        </Paper>
      )}
    </Box>
  );
}
