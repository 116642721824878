import { useQuery } from "@tanstack/react-query";
import { fetchChats } from "./fetch-chats";
import { Chats } from "./types";

export function useGetChats(){
    return useQuery<Chats[] | null, Error>({
        queryKey: ['chats'],
        queryFn: fetchChats,
    })
}



