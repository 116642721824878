import { useState, useEffect, createContext } from 'react';
import axios from 'axios';

// Create the context
export const CitationsContext = createContext();

// Create a provider component
export const CitationsContextProvider = ({ children }) => {
  const [citations, setCitations] = useState([]);
  const [id, setId] = useState(null);
  const [selectedCitation, setSelectedCitation] = useState(null);



  // Function to fetch citations based on the provided id
  const fetchCitations = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/citation/allCitations/${id}`);
      setCitations(response.data.citations);
      
    } catch (error) {
      console.error('Error fetching citations:', error);
    }
  };



  // Fetch citations every time the id changes
  useEffect(() => {
    if (id) { 
      fetchCitations(id);
    }
  }, [id]);

  // Set the default selectedCitation when citations state is updated
  useEffect(() => {
    if (citations && citations.length > 0 ) {
      setSelectedCitation(citations[0]); // Set the first citation as the default selectedCitation
    }
  }, [citations]);

  return (
    <CitationsContext.Provider value={{ citations, setId, selectedCitation, setSelectedCitation }}>
      {children}
    </CitationsContext.Provider>
  );
};
