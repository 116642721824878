
import logo from "./Logo2.jpg"


export default function SideBarHeader() {
  return (
    <div className="flex items-center px-3 pb-1 border-b">
      <div className="flex items-center py-2">
        <img
          src={logo} 
          alt="Wakili Logo"
          className="h-12 lg:h-14 mr-2"
        />
      </div>
    </div>
  );
}
