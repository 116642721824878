import { createContext, useContext, useState } from "react";

// 1. Create the context
const CollectionContext = createContext();

// 2. Create a context provider
export const CollectionProvider = ({ children }) => {
  const [collectionValue, setCollectionValue] = useState("");

  return (
    <CollectionContext.Provider value={{ collectionValue, setCollectionValue }}>
      {children}
    </CollectionContext.Provider>
  );
};

// 3. Create a custom hook to use the context
export const useCollection = () => {
  const context = useContext(CollectionContext);
  if (!context) {
    throw new Error("useCollection must be used within an CollectionProvider");
  }
  return context;
};
