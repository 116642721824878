import React from 'react';

interface OverlayComponentProps {
  isOpen: boolean;
  onClose: () => void;
  pdfUrl: string | null;
}

const OverlayComponent: React.FC<OverlayComponentProps> = ({ isOpen, onClose, pdfUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded-lg w-11/12 h-full flex flex-col">
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="text-red-500 hover:text-red-700 font-bold py-1"
          >
            close
          </button>
        </div>
        <div className="flex-grow">
          {pdfUrl ? (
            <iframe
              src={pdfUrl}
              className="w-full h-full border-none"
              title="PDF Viewer"
            />
          ) : (
            <p className="text-center">No PDF to display</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverlayComponent;