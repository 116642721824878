import { useLocation } from "react-router-dom";
import Collections from "./collections";
import SideBarChats from "./chats";
import Reports from "./reports";

export default function Side() {
  const location = useLocation();

  const renderContent = () => {
    if (location.pathname.startsWith("/wakili/chat/") || location.pathname === "/wakili" || location.pathname==='/wakili/trial2') {
      return <SideBarChats />;
    } else if (
      location.pathname === "/wakili/chatWithDoc" || 
      location.pathname.startsWith("/wakili/chatWithDoc/chat/") ||
      location.pathname.startsWith ("/wakili/addCollection")||
      location.pathname.startsWith ("/wakili/docRepo") ||
      location.pathname.startsWith ("/wakili/addDoc")
    ) {
      return <Collections />;
    } else if (location.pathname === "/wakili/kenyaGazette") {
      return <Reports />;
    } else {
      return null; 
    }
  };

  return <div>{renderContent()}</div>;
}
