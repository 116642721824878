import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { CaselawDocument, CaselawSearchParams } from "../../../../../../data/case law/types";
import { useFetchCases } from "../../../../../../data/case law/use-fetch-cases"
import OverlayComponent from "./(components)/pdf";
import { REACT_APP_BACKEND_URL } from "../../../../../../data/constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Keyword: React.FC = () => {
  const [searchParams, setSearchParams] = useState<CaselawSearchParams>({});
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(5);
  const [courtEntered, setCourtEntered] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loadingPDFs, setLoadingPDFs] = useState<Record<string, boolean>>({});


  const { data: results, isLoading, isError } = useFetchCases(searchParams);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchParams((prevParams: any) => ({ ...prevParams, [name]: value }));
    if (name === 'court') {
      setCourtEntered(value.trim() !== '');
    }
  };
  const isSearchValid = () => {
    if (courtEntered) {
      return Object.keys(searchParams).filter(key => key !== 'court' && searchParams[key as keyof CaselawSearchParams]).length > 0;
    }
    return true;
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (isSearchValid()) {
      setOffset(0);
    }

  };

  const pageCount = results ? Math.ceil(results.length / perPage) : 0;

  const handlePageClick = (data: { selected: number }) => {
    const selectedPage = data.selected;
    setOffset(selectedPage * perPage);
  };

  const paginatedResults = results ? results.slice(offset, offset + perPage) : [];




  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md text-xs overflow-y-scroll">

      <form onSubmit={handleSearch} className="grid grid-cols-2 gap-4 mb-4">
        <input
          className="border p-2 rounded"
          name="caseSummary"
          placeholder="Case Summary"
          onChange={handleInputChange}
        />
        <input
          className="border p-2 rounded"
          name="caseClass"
          placeholder="Case Class"
          onChange={handleInputChange}
        />
        <input
          className="border p-2 rounded"
          name="caseNumber"
          placeholder="Case Number"
          onChange={handleInputChange}
        />
        <input
          className="border p-2 rounded"
          name="parties"
          placeholder="Parties"
          onChange={handleInputChange}
        />
        <input
          className="border p-2 rounded"
          name="judge"
          placeholder="Judge/Magistrate"
          onChange={handleInputChange}
        />

        <input
          className="border p-2 rounded"
          name="court"
          placeholder="Court"
          onChange={handleInputChange}
        />


        <div className="">
          <span className="mr-2">Delivery date range:</span>
          <input
            type="date"
            className="border p-2 rounded mr-2"
            name="startDate"
            onChange={handleInputChange}
          />
          <span className="mx-2">to</span>
          <input
            type="date"
            className="border p-2 rounded"
            name="endDate"
            onChange={handleInputChange}
          />
        </div>

        {courtEntered && !isSearchValid() && (
          <p className="text-red-500 text-xs mt-3">
            Please enter at least one more field to refine your search when searching by Court.
          </p>
        )}
        <div className="col-span-2 flex justify-center md:justify-end">
          <button
            type="submit"
            className="text-xs Medium_Shade text-white font-semibold py-2 px-4 md:px-8 rounded-full shadow-lg flex items-center transition-all duration-300"
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
            ) : (
              <>
                Search <FontAwesomeIcon icon={faSearch} className="ml-2" />
              </>
            )}
          </button>
        </div>
      </form>



      {/* Output */}
      <div className="mt-2">
        {results && <p className="font-bold mb-2">{results.length} Search results</p>}

        {isError && <p className="text-red-500">An error occurred while fetching the data.</p>}

        <ul>
          {paginatedResults.map((caseData: CaselawDocument, index: any) => (
            <li key={index} className="mb-4">
              <div className="bg-white rounded-md shadow leading-8">
                <div className="mb-1 rounded-md Lighter_Shade py-2 text-center font-semibold">
                  {caseData["Citation"]}
                </div>
                <div className="p-4">
                  <div className="mb-1">
                    <span className="font-bold">Case Number:</span>{" "}
                    {caseData["Case Number"]}
                  </div>
                  <div className="mb-1">
                    <span className="font-bold">Date Delivered:</span>{" "}
                    {caseData["Date Delivered"]}
                  </div>
                  <div className="mb-1">
                    <span className="font-bold">Court:</span> {caseData["Court"]}
                  </div>
                  <div className="mb-1">
                    <span className="font-bold">Parties:</span> {caseData["Parties"]}
                  </div>
                  <div className="mb-1">
                    <span className="font-bold">Judge(s):</span> {caseData["Judge(s)"]}
                  </div>
                  <div className="mb-1">
                    <span className="font-bold">Citation:</span> {caseData["Citation"]}
                  </div>

                  <div className="flex justify-end">
                    <button
                      onClick={async () => {
                        const fileName = caseData?.file_name;
                        if (fileName) {
                          setLoadingPDFs(prev => ({ ...prev, [fileName]: true }));
                          setError(null);
                          const pdfUrl = `${REACT_APP_BACKEND_URL}/v1/document/getPdf?fileName=${encodeURIComponent(fileName)}`;
                          try {
                            const response = await fetch(pdfUrl);
                            if (!response.ok) throw new Error('Failed to load PDF');
                            setSelectedPdfUrl(pdfUrl);
                            setIsOverlayOpen(true);

                          } catch (err) {
                            const errorMessage = 'Failed to load PDF. Please try again.';
                            setError(errorMessage);
                            toast.error(errorMessage);
                          } finally {
                            setLoadingPDFs(prev => ({ ...prev, [fileName]: false }));
                          }
                        } else {
                          const errorMessage = 'File name is missing';
                          setError(errorMessage);
                          toast.error(errorMessage);
                        }
                      }}
                      className="Medium_Shade font-bold text-gray-700 py-1 px-4 md:px-8 rounded-full mt-2 inline-block text-center"
                    >
                      {loadingPDFs[caseData?.file_name || ''] ? (
                        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                      ) : (
                        <>
                          Read More
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>


      {/* Pagination */}
      {results && results.length > 0 && (
        <div className="mt-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={"pagination flex justify-center items-center mt-4"}
            activeClassName={"active bg-purple-500"}
            pageClassName={"page-item mx-1"}
            pageLinkClassName={"page-link px-3 py-1 border rounded"}
            previousClassName={"page-item mx-1"}
            nextClassName={"page-item mx-1"}
            previousLinkClassName={"page-link px-3 py-1 border rounded"}
            nextLinkClassName={"page-link px-3 py-1 border rounded"}
            breakLinkClassName={"page-link px-3 py-1 border rounded"}
          />
        </div>
      )}

      <OverlayComponent
        isOpen={isOverlayOpen}
        onClose={() => setIsOverlayOpen(false)}
        pdfUrl={selectedPdfUrl}
      />

      <ToastContainer />
    </div>
  );
};

export default Keyword;