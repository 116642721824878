import React, { createContext, useContext, useState, ReactNode } from "react";

interface ChatContextType {
  selectedChatId: string;
  selectedChatCollectionId: string;
  setSelectedChatId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedChatCollectionId: React.Dispatch<React.SetStateAction<string>>;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

interface ChatProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [selectedChatId, setSelectedChatId] = useState<string>("");
  const [selectedChatCollectionId, setSelectedChatCollectionId] = useState<string>("");

  return (
    <ChatContext.Provider value={{ selectedChatId, setSelectedChatId, selectedChatCollectionId, setSelectedChatCollectionId }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = (): ChatContextType => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};