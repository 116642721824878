import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useChat } from "../../../context/chatContext";

type ButtonConfig = {
  path: string;
  label: string;
  disabled?: boolean; // Optional property to control button state
};

export default function SideBarButtons() {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState<string>("");
  const { selectedChatId } = useChat();

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname.startsWith("/wakili/admin")) {
      setActiveButton("/wakili/admin");
    } else if (
      pathname.startsWith("/wakili/chat") &&
      !pathname.startsWith("/wakili/chatWithDoc") &&
      !pathname.startsWith("/wakili/kenyaGazette") &&
      !pathname.startsWith("/wakili/docDrafter") &&
      !pathname.startsWith("/wakili/addDoc") &&
      !pathname.startsWith("/wakili/addCollection") &&
      !pathname.startsWith("/wakili/docRepo")
    ) {
      setActiveButton("/wakili");
    } else if (
      pathname.startsWith("/wakili/chatWithDoc") ||
      pathname.startsWith("/wakili/addDoc") ||
      pathname.startsWith("/wakili/addCollection") ||
      pathname.startsWith("/wakili/docRepo")
    ) {
      setActiveButton("/wakili/chatWithDoc");
    } else if (pathname.startsWith("/wakili/kenyaGazette")) {
      setActiveButton("/wakili/kenyaGazette");
    } else if (pathname.startsWith("/wakili/docDrafter")) {
      setActiveButton("/wakili/docDrafter");
    }
  }, [location.pathname]);

  const handleButtonClick = (path: string, disabled: boolean) => {
    if (!disabled) {
      setActiveButton(path);
    }
  };

  const buttonClass = (path: string, disabled: boolean) =>
    `py-2 md:py-3 px-2 md:px-6 leading-4 rounded-3xl mb-2 text-gray-400 font-bold hover:-translate-y-1 duration-300 ${
      activeButton === path
        ? "Side_Buttons"
        : "bg-white border border-purple-400 NotCurrent_Buttons"
    } ${disabled ? "cursor-not-allowed opacity-50" : ""}`;

  const buttons: ButtonConfig[] = location.pathname.startsWith("/wakili/admin")
    ? [{ path: `/wakili/admin`, label: "User Management", disabled: true }]
    : [
        { path: `/wakili`, label: "Wakili Chat" },
        { path: "/wakili/chatWithDoc", label: "Chat With My Docs" },
        { path: "/wakili/kenyaGazette", label: "Kenya Law Reports" },
        { path: "/wakili/docDrafter", label: "AI Doc Drafter" },
      ];

  return (
    <div className="SideBar_Buttons">
      {buttons.map((button) => (
        <Link to={button.path} key={button.path}>
          <button
            className={buttonClass(button.path, button.disabled ?? false)}
            onClick={() => handleButtonClick(button.path, button.disabled ?? false)}
            disabled={button.disabled}
          >
            {button.label}
          </button>
        </Link>
      ))}
    </div>
  );
}
