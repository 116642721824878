

import { REACT_APP_BACKEND_URL } from "../constants";


export function useFetchChatCitations(messageId: string|undefined) {
    

    // Return a function that fetches the data
    return () => fetch(`${REACT_APP_BACKEND_URL}/v1/citation/allCitations/${messageId}`)
        .then(res => {
            if (!res.ok) {
                throw new Error('Failed to fetch chat citations');
            }
            return res.json();
        });
}
