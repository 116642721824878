import { useQuery } from '@tanstack/react-query';
import { fetchSingleUser } from './fetch-single-user';
import { User } from './types';

export const useFetchSingleUser = () => {
  return useQuery<User | null, Error>({
    queryKey: ['user'],   
    queryFn: fetchSingleUser, 
    initialData: null,                         
  });
};
