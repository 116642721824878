import { useState, useEffect } from 'react';
import SideBarHeader from "./header";
import Side from "./side";
import SideBarButtons from "./buttons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function SideBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isMobile && (
        <button
          onClick={toggleSidebar}
          className={`fixed top-4 ${isOpen ? 'right-4' : 'left-4'} z-50 bg-blue-500 text-white p-2 rounded-md`}
        >
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </button>
      )}
      <section
        className={`SideBar flex-grow min-h-[100vh] max-h-[100vh] rounded-2xl w-full md:w-1/6 flex flex-col fixed md:relative top-0 left-0 h-full bg-white transition-transform duration-300 ease-in-out ${isMobile && !isOpen ? '-translate-x-full' : 'translate-x-0'} z-40`}
      >
        <SideBarHeader />
        <div className="flex-grow flex flex-col justify-between">
          <div className="Side_Chats py-2 max-h-[50vh] overflow-scroll pb-4">
            <Side />
          </div>
          <div className="mt-auto">
            <SideBarButtons />
          </div>
        </div>
      </section>
    </>
  );
}
