import React, { KeyboardEvent } from 'react';
import { useLocation } from 'react-router-dom'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Star from "../../../../img/Star.png";

interface FooterProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  isLoading: boolean;
  handlePromptSubmit: (e: React.FormEvent) => void;
  response: string;
  specificFooter?: boolean;
}

const FooterStreaming: React.FC<FooterProps> = ({ 
  inputValue, 
  setInputValue, 
  isLoading, 
  handlePromptSubmit, 
  response,
 
}) => {

  const location = useLocation(); 
  const specificFooter = location.pathname.startsWith('/wakili/chat/');
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handlePromptSubmit(e);
    }
  };

  return (
    <div className="ChatView_Footer  w-full md:w-[90%] m-auto">
      <form onSubmit={handlePromptSubmit} className='flex'>
        <textarea
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          className="w-4/5 px-3 py-1 border border-gray-300 focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-500"
        />
        <button
          type="submit"
          className="w-1/5 flex justify-center items-center bg-gradient-to-r from-linearBlue1 to-linearBlue2 self-end"
          disabled={!inputValue.trim() || isLoading}
        >
          {isLoading ? (
            <div>
              <FontAwesomeIcon className="loader" icon={faSpinner} />
            </div>
          ) : (
            <div className="flex items-center">
              <span>Ask Wakili</span>
              <img
                src={Star}
                alt="Star image"
                className="h-[16px] w-[16px] ml-2"
                style={{ filter: "brightness(0) invert(1)" }} 
              />
            </div>
          )}
        </button>
      </form>

      {!specificFooter ? (
        <p className="text-center" style={{ fontSize: "10px" }}>
          This model you are using has been trained on your uploaded documents.
        </p>
      ) : (
        <p className="text-center" style={{ fontSize: "10px" }}>
          The Lawlyfy model you are chatting with has been trained on all Kenyan Case Law Documents filed as of <span className="font-black">August 2024.</span>
        </p>
      )}
    </div>
  );
};

export default FooterStreaming;