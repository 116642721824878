import FooterImg from "../../img/Footer.jpg"
import Logo2 from "../../img/Logo2.jpg"

export default function Footer(){
    return(
        <section id="contact" className="flex flex-col">
          <div className="mx-auto w-full relative max-w-7xl">
            <img src={FooterImg} alt="Footer" className="w-full" />
            <div className="absolute inset-x-0 top-[10px] md:top-[100px]  flex flex-col items-center justify-center text-gray-900">
              <h3 className=" text-base lg:text-xl font-bold mb-2 lg:mb-4">
                Take Back Your Time
              </h3>
              <a
                href="/register"
                className="inline-block bg-gray-900 text-white text-xs py-2 px-4 rounded-full hover:bg-gray-800"
              >
                Let's get started
              </a>
            </div>
          </div>
          <div className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8 py-12 flex flex-col md:flex-row justify-between ">
            <div className="flex-1 text-xs leading-loose pb-10 lg:pb-6">
              <img src={Logo2} alt="Wakili Logo" className="h-8 lg:h-10" />

              <div className="py-3 text-xs font-semibold text-gray-700">
                <h4 className="font-bold">Address</h4>
                <p>Le Mac Building, Westlands, Nairobi</p>
              </div>

              <div className="pb-3 pt-1 text-xs font-semibold text-gray-700">
                <h4 className="font-bold">Contact:</h4>
                <p>+254-727-0909-33</p>
                <p>sure@lawlyfy.ai</p>
              </div>

              <div className="flex items-center space-x-4 py-3">
                <a
                  href="https://x.com/lawlyfy"
                  className="hover:-translate-y-1 delay-150 transition-all duration-300 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#62409A"
                  >
                    <path d="M22.46 0H17.5l-5.29 8.82L6.91 0H1.54l7.61 12L1.1 24h5.87l5.42-8.75L17.97 24h5.27l-7.51-12z"/>
                  </svg>
                </a>

                <a
                  href="https://www.instagram.com/lawlyfy.ai/"
                  className="hover:-translate-y-1 delay-150 transition-all duration-300 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#62409A"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162 0 3.403 2.759 6.162 6.162 6.162 3.403 0 6.162-2.759 6.162-6.162 0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4 2.209 0 4 1.791 4 4 0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44 0 .795.645 1.44 1.441 1.44.795 0 1.439-.645 1.439-1.44 0-.795-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/lawlyfy/"
                  className="hover:-translate-y-1 delay-150 transition-all duration-300 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#62409A"
                  >
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
                <a
                  href="https://www.youtube.com/@LAWLYFYai"
                  className="hover:-translate-y-1 delay-150 transition-all duration-300 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#62409A"
                  >
                    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.985-8 4.015z" />
                  </svg>
                </a>
              </div>
            </div>

            <div className="flex-1 flex justify-between lg:justify-end md:gap-x-16 lg:gap-x-32 ">
              <div>
                <h3 className="text-base font-semibold">Useful Links</h3>
                <div className="flex flex-col gap-3 py-4">
                  <a
                    href="/login"
                    className="text-xs font-semibold text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                  >
                    Sign In
                  </a>

                  <a
                    href="/register"
                    className="text-xs font-semibold text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                  >
                    Sign Up
                  </a>

                  <a
                    href="/forgetPassword"
                    className="text-xs font-semibold text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                  >
                    Forgot Password
                  </a>
                </div>
              </div>

             {/*  <div>
                <h3 className="text-base font-semibold">Our Services</h3>
                <div className="flex flex-col gap-3 py-4">
                  <a
                    href="#features"
                    className="text-xs font-semibold text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                  >
                    About Us
                  </a>

                  <a
                    href="#blog"
                    className="text-xs font-semibold text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                  >
                    Blog
                  </a>

                  <a
                    href="#pricing"
                    className="text-xs font-semibold text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                  >
                    Pricing
                  </a>

                  <a
                    href="#contact"
                    className="text-xs font-semibold text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                  >
                    Contact
                  </a>

                  <a
                    href="https://calendly.com/sureantony-jguw/15min"
                    className="text-xs font-semibold text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                  >
                    Book a Demo
                  </a>
                </div>
              </div>*/}
            </div>
          </div>
        </section>
    )
}