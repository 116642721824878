import { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { validationSchema } from "./validation";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const useForgetPasswordForm = () => {

 
  const [err, setError] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      Email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting,  resetForm }) => {
      try {
        const emailCheckResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/checkEmail/${values.Email}`);

        if (emailCheckResponse.data === false) {
          setError('Email not found. Please enter a valid email address.');
          return;
        }

        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/forgetPassword/${values.Email}`);
        
        resetForm();

        toast.info('We\'ve sent you a link to verify your email. Check your mail box ',{
          position: 'top-right',
          autoClose: 2000,
        });
      } catch (error: any) {
        setError(error.response?.data || "An unexpected error occurred");
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return { formik, err, loading };
};
