import { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import LayersIcon from "@mui/icons-material/Layers";
import { useGetCollections } from "../../../data/collections/use-get-collections";
import { useGetChats } from "../../../data/chats/use-get-chats";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useCollection } from "../../../context/collectionContext";
import { useChat } from "../../../context/chatContext";
import Star from "../../../img/Star.png";

export default function Collections() {
  const { collectionValue, setCollectionValue } = useCollection();
  const { selectedChatCollectionId, setSelectedChatCollectionId } = useChat();
  const { data: collections } = useGetCollections();
  const { data: chats } = useGetChats();
  const { chatId } = useParams();
  const chatListRef = useRef<HTMLDivElement>(null);
  const topMostCollectionRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const specialRoutes = ['/wakili/docRepo', '/wakili/addDoc', '/wakili/addCollection'];

  useEffect(() => {
    if (collections && collections.length > 0) {
      if (!collectionValue) {
        setCollectionValue(collections[collections.length - 1]);
      }
      if (topMostCollectionRef.current && collections[collections.length - 1] === collectionValue) {
        topMostCollectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [collections, collectionValue, setCollectionValue]);

  useEffect(() => {
    if (!specialRoutes.includes(location.pathname) && 
        collections && collections.length > 0) {
      if (!selectedChatCollectionId) {
        const latestCollection = collections[collections.length - 1];
        setSelectedChatCollectionId(latestCollection.chatId);
        navigate(`/wakili/chatWithDoc/chat/${latestCollection.chatId}`);
      } else if (chatId !== selectedChatCollectionId) {
        navigate(`/wakili/chatWithDoc/chat/${selectedChatCollectionId}`);
      }
    }
  }, [collections, selectedChatCollectionId, setSelectedChatCollectionId, navigate, location.pathname, chatId]);

  const handleCollectionClick = (collection: any) => {
    setCollectionValue(collection);
    setSelectedChatCollectionId(collection.chatId);

    if (chats) {
      const associatedChat = chats.find(
        (chat) => chat.collectionId === collection._id
      );
      if (associatedChat) {
        navigate(`/wakili/chatWithDoc/chat/${associatedChat._id}`);
      } else {
        console.error("No chat found for the selected collection");
      }
    } else {
      console.error("Chats data is undefined");
    }
  };

  if (!collections) {
    return null;
  }
  return (
    <div className="px-2 mb-2 mt-5">
       <div className="pb-5 px-1 flex justify-between items-center text-xs font-bold gap-2 text-gray-700">
        <div className="flex gap-x-1 items-center">
         
          <img src={Star} alt="star image" className="Chats_Header_Icon h-[12px] w-[12px]" />
          <p className="text-xs font-bold">My Document Collections</p>
        </div>
      </div>

      
      <Link to="addCollection">
        <button className="mb-2 ml-1 text-[11px] flex items-center px-1 py-2 bg-purple-100 text-gray-800 font-bold rounded-lg transition-transform ease-in-out hover:bg-purple-200 ">
          <FontAwesomeIcon
            icon={faMagicWandSparkles}
            className="text-purple-500 mr-1 "
          />
          Create New Collection
        </button>
      </Link>

      <div className="Chats text-xs" ref={chatListRef} >
        {collections && collections.map((collection, index) => (
          <div
          className={`flex ${
            collection.chatId === selectedChatCollectionId ? "Selected_Chat" : ""
          }`}
            key={collection._id}
            onClick={() => handleCollectionClick(collection)}
            ref={index === collections.length - 1 ? topMostCollectionRef : null}
          >
            <LayersIcon className="Chats_Icon" />
            <div className="Chat_Name">{collection.name}</div>
          </div>
        ))}
      </div>
      

    </div>
  );
}
