import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../constants";


const useCreateChat = () => {

  const createNewChat = async () => {
    const token = localStorage.getItem('token'); 

  if (token === null) {
    console.error("User is not logged in."); 
    return null; 
  }

    try {
      const newChatResponse = await axios.post(
        `${REACT_APP_BACKEND_URL}/v1/chat/createChat`,
        {
          specificChat: false,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'  
          }
        }
      );

      const newChatId = newChatResponse.data._id;

      return newChatId;
    } catch (error) {
      console.error("Failed to create a new chat", error);
      return null;
    }
  };

  return createNewChat;
};

export default useCreateChat;
