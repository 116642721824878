import { REACT_APP_BACKEND_URL} from "../constants";
import { Chats } from "./types";



export async function fetchChats(): Promise<Chats[] | null> {
  const token = localStorage.getItem('token'); 

  if (token === null) {
    console.error("User is not logged in."); 
    return null; 
  }


  return fetch(`${REACT_APP_BACKEND_URL}/v1/chat/getAllChats`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'  
    }
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error("Failed to fetch chats.");
      }
      return res.json();
    })
    .then((chats) => {
      return chats;
    });
}
