import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

function Trial2() {
  return (
    <div className="flex justify-center items-center min-h-[85vh] bg-gray-100">
      <div className="bg-white shadow-lg rounded-xl border border-gray-200 p-6 max-w-md w-full text-center">
        <FontAwesomeIcon icon={faLock} className="text-gray-500 text-5xl mb-4" />
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Access Restricted
        </h2>
        <p className="text-gray-600 mb-4">
          The feature you want to access is not available under your current subscription plan.
        </p>
        <a
          href="/#pricing"
          className="inline-block bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
        >
          Upgrade Plan
        </a>
        <p className="mt-3 text-xs text-gray-500">
          Visit our website to explore other available options.
        </p>
      </div>
    </div>
  );
}

export default Trial2;
