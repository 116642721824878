import { useState, useEffect, useCallback } from "react";

export const useSSEStreaming = () => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [response, setResponse] = useState("");
  const [citationz, setCitations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const startStreaming = useCallback(
    async (requestBody, tokenUrl, streamUrl) => {
      if (isConnecting) {
        console.log("Connection attempt is already in progress.");
        return;
      }
      setIsConnecting(true);
      setLoading(true);
      setCompleted(false);
      setResponse("");
      setCitations([]);
      setErrorMessage("");

      let eventSource = null;
      const cleanupEventSource = () => {
        if (eventSource) {
          eventSource.close();
          setCompleted(true);
          eventSource = null;
        }
      };

      try {
        const requestBodyString = JSON.stringify(requestBody);
        const res = await fetch(tokenUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestBodyString,
        });

        const data = await res.json();
        if (data.token) {
          const sseUrl = `${streamUrl}?token=${encodeURIComponent(data.token)}`;
          eventSource = new EventSource(sseUrl);

          eventSource.onmessage = (event) => {
            const eventData = JSON.parse(event.data);
            if (eventData.response) {
              setResponse((prev) => prev + eventData.response);
            }
            if (
              eventData.citation &&
              Array.isArray(eventData.citation) &&
              eventData.citation.length
            ) {
              setCitations(() => eventData.citation);
            }
          };

          eventSource.onerror = (error) => {
            //setErrorMessage("A connection error occurred.");
            setIsConnecting(false);
            setLoading(false);
            cleanupEventSource();
          };

          eventSource.onopen = () => {
            setIsConnecting(false);
            // Set completed to true when connection is successfully opened
            console.log("EventSource connection opened");
          };

          // Cleanup the EventSource connection when the component unmounts or dependencies change
          return cleanupEventSource;
        } else {
          throw new Error("Token not received");
        }
      } catch (error) {
        setIsConnecting(false);
        console.error("Error initializing EventSource:", error);
      
        setLoading(false);
        cleanupEventSource();
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    return startStreaming;
  }, [startStreaming]);

  return { response, citationz, loading, completed, startStreaming, errorMessage };
};
