import { REACT_APP_BACKEND_URL } from "../constants";
import axios from 'axios';
import { User } from './types';

export const fetchSingleUser = async (): Promise<User | null> => {
  const token = localStorage.getItem('token'); 

  if (token === null) {
    console.error("User is not logged in."); 
    return null; 
  }

  try {
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/v1/auth/users`, {
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json'  
      }
    });
    console.log('RESPONSEEE=>', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user:', error);
    // Handle error appropriately; could return null or rethrow depending on the scenario
    return null;
  }
};
