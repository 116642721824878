import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useResetPasswordForm } from "./(components)/resetPasswordForm";

export default function ResetPassword() {
  const { formik, err, loading } = useResetPasswordForm();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  return (
    <div className=" bg-slate-50 h-screen pt-10 mx-auto max-w-7xl">
      <form
        onSubmit={formik.handleSubmit}
        className="w-[90vw] md:w-[30vw] text-[#615f5f] rounded-2xl bg-white m-auto h-auto px-8 py-6"
      >
        <h1 className="text-center text-xl font-bold leading-6 mb-2">
          Reset Your Password
        </h1>
        <p className="text-center text-sm font-semibold leading-6 mb-4">
          Enter your new password
        </p>
        {err && <p className="text-xs text-red-600 mt-1">{err}</p>}

        <div key="Password" className="mb-4">
          <label className="block text-xs font-bold mb-2">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              name="Password"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Password}
              className="shadow-sm appearance-none text-xs border rounded w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline pr-12" // Added pr-12 for padding-right
            />
            <span
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" // Added absolute positioning and utility classNamees for alignment
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </span>
          </div>
          <small style={{ fontSize: "9px", textDecoration: "underline" }}>
            Minimum 8 characters
          </small>{" "}
          <br />
          <small className="formik-error text-red-600">
            {formik.touched.Password && formik.errors.Password}
          </small>
        </div>

        <div key="ConfirmPassword" className="mb-4">
          <label className="block text-xs font-bold mb-2">
            Confirm Password
          </label>
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              name="ConfirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ConfirmPassword}
              className="shadow-sm appearance-none text-xs border rounded w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
            />
            <span
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </span>
          </div>
          <small style={{ fontSize: "9px", textDecoration: "underline" }}>
            Minimum 8 characters
          </small>{" "}
          <br />
          <small className="formik-error text-red-600">
            {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword}
          </small>
        </div>

        <div className="flex flex-col">
          <button className="flex items-center justify-center bg-gradient-to-r from-linearBlue1 to-linearBlue2 mb-4 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            {loading ? <div className="loader"></div> : "Reset Password"}
          </button>
        </div>
      </form>
      <ToastContainer containerId='toast-container-success'/>
      <ToastContainer containerId='toast-container-reset-failure'/>
    </div>
  );
}
