import React, { createContext, useContext, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface NotificationContextType {
  setNotification: (message: string, status: 'pending' | 'success' | 'failure') => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  let pendingToastId = useRef<React.ReactText | null>(null); // Ref to store toast IDs
  let successToastId = useRef<React.ReactText | null>(null); // Ref for success toast
  let failureToastId = useRef<React.ReactText | null>(null); // Ref for failure toast

  const setNotification = (msg: string, status: 'pending' | 'success' | 'failure') => {
    // Handle "pending" status
    if (status === 'pending') {
      // Only show pending if no other toast is active
      if (!pendingToastId.current && !successToastId.current && !failureToastId.current) {
        pendingToastId.current = toast.info(msg, { position: "top-right", autoClose: false });
      }
    } else {
      // Dismiss the pending toast if it's active
      if (pendingToastId.current) {
        toast.dismiss(pendingToastId.current);
        pendingToastId.current = null; // Clear pending toastId
      }

      // Handle "failure" status
      if (status === 'failure') {
        // Only show failure if success is not active
        if (!successToastId.current && !failureToastId.current) {
          failureToastId.current = toast.error(msg, { position: "top-right", autoClose: 3000 });
        }
      }

      // Handle "success" status
      if (status === 'success') {
        // Dismiss the failure toast if it's active
        if (failureToastId.current) {
          toast.dismiss(failureToastId.current);
          failureToastId.current = null; // Clear failure toastId
        }
        // Show success
        if (!successToastId.current) {
          successToastId.current = toast.success(msg, { position: "top-right", autoClose: 3000 });
        }
      }
    }
  };

  return (
    <NotificationContext.Provider value={{ setNotification }}>
      {children}
      <ToastContainer />
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
