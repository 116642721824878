import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../constants";





export const DeleteDocument = async (id: string) => {
  try {
    // Delete document from your backend
    const response = await axios.delete(
      `${REACT_APP_BACKEND_URL}/v1/document/deleteDocument/${id}`
    );

   

    return response.data;
  } catch (error) {
  
    throw new Error("Failed to delete document");
  }
};




    /* Remove from LLM
    // Get the context and filename from the response data if available
    const { context, filename } = response.data.document;
    
    if (context && filename) {
      try {
        const llmResponse = await axios.delete(
          `${STAGING_URL}/wakili/delete`,
          {
            data: {
              context: `${context}_collection`,
              filename: filename,
            }
          }
        );

        if (llmResponse.status !== 200) {
          console.log("Failed to remove document from LLM:", llmResponse.data);
        }
      } catch (error: any) {
       console.log(error.data.message)
        console.error("Error removing document from LLM:", error);
      }
    }*/