import { useState, useEffect } from "react";
import useCreateChat from "../../../../data/chats/createNewChat";
import useFetchDataFromAI from "../../../../data/prompts/useFetchDataFromAI";
import useSendDataToDatabase from "../../../../data/prompts/useSendDataToDatabase";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer/page";
import { useInput } from "../../../../context/inputContext";
import W from "../../../../img/Background.png";
import Star from "../../../../img/Star.png";
import { Dialog, Transition } from "@headlessui/react";

const WakiliChat = () => {
  const { inputValueContext } = useInput();
  const { chatId } = useParams<{ chatId: string }>();

  const [clickedPrompts, setClickedPrompts] = useState<{
    [key: string]: boolean;
  }>({
    prompt1: false,
    prompt2: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const createNewChat = useCreateChat();
  const fetchDataFromAI = useFetchDataFromAI;
  const { sendDataToDatabase } = useSendDataToDatabase();

  const handlePromptClick = async (inputValue: string, promptKey: string) => {
    if (clickedPrompts[promptKey]) {
      return;
    }

    setClickedPrompts((prev) => ({ ...prev, [promptKey]: true }));

    setLoading(true);

    let newChatId = chatId;

    if (!chatId) {
      newChatId = await createNewChat();
    }

    const promptResponse = await fetchDataFromAI(inputValue, newChatId);
    if (promptResponse !== null) {
      await sendDataToDatabase({
        chatId: newChatId,
        promptText: inputValue,
        response: promptResponse.response,
        citations: promptResponse.citation,
      });
    }
  };

  useEffect(() => {
    // Check if the modal has been shown before
    const hasSeenModal = localStorage.getItem('hasSeenWakiliModal');

    if (hasSeenModal === 'false') {
      // Show the modal if it hasn't been shown before
      setIsModalOpen(true);
      // Update localStorage to indicate that the modal has been shown
      localStorage.setItem('hasSeenWakiliModal', 'true');
    }
  }, []);

  return (
    <div className="ChatView_Section">
      {/* 
      <div className="Clicked_Chats flex items-center justify-center">
        {inputValueContext == "" && <img src={W} alt="background" />}

        {inputValueContext !== "" && (
          <div className="Clicked_Chat">
            <div className="flex justify-start items-center gap-3">
              <div className="User w-10 h-9 rounded-full flex justify-center items-center bg-white text-sm">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-xs text-primary"
                />
              </div>
              <div className="Prompt w-full rounded-[16px] py-3 px-4 flex justify-between items-center leading-loose">
                <p className="text-sm font-semibold">{inputValueContext}</p>
              
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />

    
      <Transition show={isModalOpen}>
        <Dialog
          onClose={() => setIsModalOpen(false)}
          className="fixed ml-12 inset-0 z-10 overflow-y-auto"
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-30"
              leave="ease-in duration-200"
              leaveFrom="opacity-30"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
            </Transition.Child>

            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative bg-white p-6 rounded-lg max-w-xl mx-auto">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-white rounded-xs text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-linearBlue1"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <FontAwesomeIcon icon={faClose} />
                    <span className="sr-only">Close</span>
                  </button>
                </div>

                <Dialog.Title className="text-xl text-center font-bold mb-4">
                  Welcome to WAKILI
                </Dialog.Title>

                <Dialog.Description>
                  <p className="text-center mb-6 text-sm">
                    Your one-stop-shop for all your legal research needs. Wakili
                    offers:
                  </p>
                  <ul className="space-y-4 text-sm">
                    <li className="flex items-center space-x-2">
                      <img src={Star} alt="Star" className="h-4 w-4 mt-1" />
                      <div>
                        <span className="font-semibold text-linearBlue1">Wakili Chat</span> for
                        general research and strategy formulation
                      </div>
                    </li>
                    <li className="flex items-center space-x-2">
                      <img src={Star} alt="Star" className="h-4 w-4 mt-1" />
                      <div>
                        <span className="font-semibold text-linearBlue1">Chat With My Docs</span>{" "}
                        to harness your personal data and documents for research
                      </div>
                    </li>
                    <li className="flex items-center space-x-2">
                      <img src={Star} alt="Star" className="h-4 w-4 mt-1" />
                      <div>
                        <span className="font-semibold text-linearBlue1">Kenya Law Reports</span>{" "}
                        to seamlessly search across Kenya’s case law, the Kenya
                        gazette, laws of Kenya, and the cause list
                      </div>
                    </li>
                    <li className="flex items-center space-x-2">
                      <img src={Star} alt="Star" className="h-4 w-4 mt-1" />
                      <div>
                        <span className="font-semibold text-linearBlue1">AI Doc Drafter</span> to
                        draft documents adhering to your templates
                      </div>
                    </li>
                  </ul>
                </Dialog.Description>
                <div className="flex justify-center mt-6">
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className=" bg-gradient-to-r from-linearBlue1 to-linearBlue2 text-white px-4 py-2 rounded-lg"
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>*/}
    </div>
  );
};

export default WakiliChat;


