

/*import { REACT_APP_BACKEND_URL } from "../constants";


export function useFetchChatMessages(chatId: string|undefined) {
    

    return () => fetch(`${REACT_APP_BACKEND_URL}/v1/chat/getChat/${chatId}`)
        .then(res => {
            if (!res.ok) {
                throw new Error('Failed to fetch chat messages');
            }
            
            return res.json();
        });
}*/

import { REACT_APP_BACKEND_URL } from "../constants";

export function useFetchChatMessages(chatId: string | undefined) {
    return async () => {
        try {
            const response = await fetch(`${REACT_APP_BACKEND_URL}/v1/chat/getChat/${chatId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch chat messages');
            }
            const data = await response.json();
           
            return data;
        } catch (error) {
            console.error('Error fetching chat messages:', error);
            throw error;
        }
    };
}

