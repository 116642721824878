import { useGetChatMessages } from "../data/chats/use-get-chat-messages";
import { useParams } from "react-router-dom";

const Trial = () => {
  const { chatId } = useParams();
  const { data: messages, isLoading } = useGetChatMessages(chatId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!messages) {
    return <div>No messages found</div>;
  }

  if (messages){
    console.log(messages)
  }

  return (
    <div>
{messages.messages.map((message) => (
        <div key={message._id}>
          <ul>
            <li>{message.promptText}</li>
            <li>{message.response}</li>
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Trial;