import React, { useState } from 'react';
import Keyword from './(components)/Keyword/page';
import SemanticAI from './(components)/SemanticAI/page';
import Star from '../../../../img/Star.png';


const KenyaGazette: React.FC = () => {
  const [searchMethod, setSearchMethod] = useState<'Keyword' | 'Semantic AI'>('Keyword');

  const renderSearchForm = () => {
    if (searchMethod === 'Keyword') {
      return (
        <Keyword/>
      );
    } else {
      return (
        <SemanticAI/>
      );
    }
  };

  return (
    <div className="ChatView_Section px-8">
      <div className="flex items-center mb-6 text-xs">
        <p className ='mr-1 md:mr-5 text-gray-500 font-semibold'>Search By </p>
        <div className="bg-white rounded-full shadow-md p-1 flex font-semibold">
          <button
            className={`py-2 px-4 md:px-16 rounded-full text-gray-700 ${searchMethod === 'Keyword' ? 'Lighter_Shade' : ''}`}
            onClick={() => setSearchMethod('Keyword')}
          >
            Keyword
          </button>
          <button
            className={`py-2 px-4 md:px-16 rounded-full flex gap-3 text-gray-700 ${searchMethod === 'Semantic AI' ? 'Lighter_Shade' : ''}`}
            onClick={() => setSearchMethod('Semantic AI')}
          >
            Semantic AI
            <img src={Star} alt="star image" className="Chats_Header_Icon h-[16px] w-[16px]" />
          </button>
        </div>
      </div>
      {renderSearchForm()}
    </div>
  );
};

export default KenyaGazette;
